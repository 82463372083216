import React, {useRef, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Step1 from "../../images/tips/checkaddress.svg";
import Step2 from "../../images/tips/chooseplan.svg";
import Step3 from "../../images/tips/signup.svg";
import Step4 from "../../images/tips/getonline.svg";
import { executeScroll } from "./utils";
import { useQueryParam, StringParam } from "use-query-params";

export default function Tips(props) {
  const myRef = useRef(null);
  const [defaultTab] = useQueryParam("section", StringParam);
  useEffect(() => {
    if (defaultTab === "plans") {
      executeScroll(myRef);
    }
  }, [defaultTab, myRef, executeScroll]);
  return (
    <Container className="tips-section" ref={myRef}>
      <Row>
        <Col>
          <div className="main-text text-center my-3">
            {"Getting connected is easy"}
          </div>
          <div className="sub-text text-center my-3">
            {"follow these steps"}
          </div>
        </Col>
      </Row>
      <Link to="/?section=join" className="font-black">
        <Row>
          <Col xs={12} lg={3} className="mouse-over-enlarge">
            <div className="details my-4">
              <span className="step mr-3">{"1"}</span>
              {"Check Address"}
            </div>
            <img className="step-img" src={Step1} />
            <div className="desc my-3">
              {"Enter your address and see if it is available"}
            </div>
            <i className="fa fa-arrow-right next-step d-none d-lg-block" />
          </Col>
          <Col xs={12} lg={3} className="mouse-over-enlarge">
            <div className="details my-4">
              <span className="step mr-3">{"2"}</span>
              {"Choose your plan"}
            </div>
            <img className="step-img" src={Step2} />
            <div className="desc my-3">
              {"Pick your Internet plan and get your special offer"}
            </div>
            <i className="fa fa-arrow-right next-step d-none d-lg-block" />
          </Col>
          <Col xs={12} lg={3} className="mouse-over-enlarge">
            <div className="details my-4">
              <span className="step mr-3">{"3"}</span>
              {"Sign up"}
            </div>
            <img className="step-img" src={Step3} />
            <div className="desc my-3">{"Signing up is easy"}</div>
            <i className="fa fa-arrow-right next-step d-none d-lg-block" />
          </Col>
          <Col xs={12} lg={3} className="mouse-over-enlarge">
            <div className="details my-4">
              <span className="step mr-3">{"4"}</span>
              {"Get Online"}
            </div>
            <img className="step-img" src={Step4} />
            <div className="desc my-3">{"Enjoy stress free internet"}</div>
          </Col>
        </Row>
      </Link>
    </Container>
  );
}
