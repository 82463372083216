import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import ContactForm from "../contact-us";

export default function Inquiry(props) {
  const [open, setOpen] = useAsyncSetState(false);
  return (
    <Container className="inquiry-section">
      <Row>
        <Col style={{marginTop: -100}} className="mx-auto" xs={12} md={6}>
        <div className="inquiry-group">
            <div className="main-text my-3">{"Talk to our Sales Team"}</div>
            <div className="sub-text my-3">
              {
                "We’ll help you find the right solution for your needs."
              }
            </div>
            <Button onClick={() => window.groove.widget.toggle()} variant="yellow">{"Contact Us"}</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
