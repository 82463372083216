import { gql } from "@apollo/client";

export const searchServiceAvailabilityQuery = gql`
query searchServiceAvailability($address: String) {
  classMethods {
    Address {
      searchAddressServiceability(address: $address) {
        id
        locationId
        unitNo,
        streetNo
        address
        suburb
        state
        postCode
        country
        countryId
        accessCircuitIds
        isNewConnection
        building {
          code
          cisLink
        }
        systemProviders {
          id
          name
          icon
          hoverIcon
          targetUri
          notManaged
        }
      }
    }
  }
}`;

export function searchServiceAvailabilityResult({data}) {
  return data?.classMethods?.Address?.searchAddressServiceability || [];
}


export const getBuildingCIS = gql`
  query getBuildingCIS ($code: String) {
    classMethods {
      Building {
        getBuildingCIS (code: $code)
      }
    }
  }
`;
export const getAddressQuery = gql`
  query getAddress ($id: ID!) {
    models {
      Address (where: {
        id: {eq: $id}
      }) {
        edges {
          node {
            id
            streetNo
            address
            suburb
            state
            postCode
          }
        }
      }
    }
  }
`;


export const getCountriesQuery = gql`  query getCountries {
  models {
    Country {
        edges {
            node {
                id
                name
            }
        }
    }
  }
}`;
export const getCountriesResult = ({data}) => (data?.models?.Country?.edges || []).map(({node}) => node); 

export function formatLocation(location) {
  return `${location?.unitNo ? ` ${location.unitNo}/` : " "}${location.streetNo} ${location.address}, ${location.suburb}, ${location.state} ${location.postCode}${location?.country ? `, ${location?.country}` : ""}`;
}