import React from "react";
import {Row, Col, Button} from "react-bootstrap";
import Banner from "../components/section/banner";
import PlanSection from "../components/section/plans";
import WhySection from "../components/section/why";
import IPSection from "../components/section/ip-section";
import FaqSection from "../components/section/faq";
import SEO from "../components/section/seo";
import Main from "../components/main";
import Header from "../components/section/header";
import Footer from "../components/section/footer";
import Inquiry from "../components/section/inquiry";
import Tips from "../components/section/tips";
import AddressLocator from "../components/address-selector/index";

export default function IndexPage() {
  return (
    <>
      <Main>
        <SEO title="SpeedyTel">
          <meta name="description" content="Delivering speedy internet 24/7" />
        </SEO>
        <Header />
        <Banner>
          <Row>
            <Col xs={12} lg={8}>
              <div className="main-text font-white">
                <div>
                  {"Fast reliable internet."}
                </div>
                <div>
                  {"That's what we do."}
                </div>
              </div>
              <div className="sub-text font-white font-secondary">
                {"Great value plans delivering speedy internet to your home or business"}
                {/* {"Future-driven focus to deliver impressive internet at better prices. "}
                <span className="bold">{"Faster speeds that you can tell."}</span> */}
              </div>
              {/* <div className="sub-text font-white font-secondary bold">
                {"See if speedytel is available at your address"}
              </div> */}
            </Col>
          </Row>
          <Row className="my-5">
            <Col xs={12} xl={9}>
              <AddressLocator
                home
                inline
                isWhite
                highlight
                showLocator
              />
            </Col>
          </Row>
        </Banner>
        <Inquiry />
        <Tips />
        <PlanSection />
        <WhySection />
        {/* <IPSection /> */}
        {/* <FaqSection /> */}
        <Footer />
      </Main>
    </>
  );
}
