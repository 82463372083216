import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NoLockInImg from "../../images/why/nolockin.svg"
import supportImg from "../../images/why/networksupport.svg";
import seamlessSignup from "../../images/why/seamles.svg";
import { useQueryParam, StringParam } from "use-query-params";
import { executeScroll } from "./utils";

export default function WhySection(props) {
	const myRef = useRef(null);
	const [defaultTab] = useQueryParam("section", StringParam);

	useEffect(() => {
		if (defaultTab === "why") {
			executeScroll(myRef);
		}
	}, [defaultTab, myRef]);
	return (
		<div className="why-section" ref={myRef}>
			<Container>
				<Row>
					<Col>
						<div className="main-text">
							{"Why SpeedyTel"}
						</div>
						<div className="sub-text font-secondary">
							{"We are an Internet service provider offering high-performance great-value internet."}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<div className="why-item">
							<img src={NoLockInImg} alt="no lock in" className="why-img" />
							<div className="why-title">
								{"No lock-in contract"}
							</div>
							<div className="why-subtitle">
								{"Our users stay with us because we deliver the speed and service they require."}
							</div>
 						</div>
					</Col>
					<Col xs={12} md={4}>
						<div className="why-item">
							<img src={supportImg} alt="no lock in" className="why-img" />
							<div className="why-title my-3">
								{"Network support"}
							</div>
							<div className="why-subtitle my-3">
								{"Our support team will help keep you online, all the time."}
							</div>
						</div>
					</Col>
					<Col xs={12} md={4}>
						<div className="why-item">
							<img src={seamlessSignup} alt="no lock in" className="why-img" />
							<div className="why-title">
								{"Easy sign-up"}
							</div>
							<div className="why-subtitle">
								{"We make signing up quick and easy."}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}